import Mainaxios from 'axios';
// let youtubeAxios = require('axios');


// let token = "";

export default  Mainaxios.create({
    // baseURL: 'https://admin.indiakhabar.co.in/',
    baseURL: process.env.VUE_APP_BASE_URL,
    headers: {
        "Authorization": `Bearer ${process.env.VUE_APP_TOKEN}`
    }
});

