<template>
  <div>
    <!-- Featured News Slider Start -->
    <div class="container-fluid pt-5 mb-3">
        <div class="container">
            <div class="section-title">
                <h4 class="m-0 text-uppercase font-weight-bold">Featured News</h4>
            </div>
			<carousel :perPageCustom="[[320, 1], [1199, 4]]" :autoplay="true"  :loop="true" :paginationEnabled="false">
				<slide class="px-3" v-for="obj in features" :key="obj.key" >
                <div class="position-relative overflow-hidden" style="height: 300px;">
					<span v-for="(image,index) in obj.images" :key="index">
						<img class="img-fluid w-100 h-100" :src="$urlpath+image">
					</span>
                    <div class="overlay">
                        <div class="mb-2">
							<router-link :to="'/category/'+obj.categ_id" class="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2" >
                                    {{obj.categ_name}}
                                  </router-link> <br>
                            <a class="text-white" href=""><small><fa :icon="['far', 'clock']" /> {{obj.postdate|calendar}}</small></a>
                        </div>
						<router-link class="h6 m-0 text-white text-uppercase font-weight-semi-bold" :to="'/single-post/'+obj.id">{{obj.title|truncate(40)}}</router-link>
                    </div>
                </div>
				</slide>
			</carousel>
			
        </div>
    </div>
    <!-- Featured News Slider End -->

    
  </div>
</template>

<script>
import Mainaxios from '../Api'
export default {
  name: "FeaturedNews",
  props: {
    msg: String,
  },
  data(){
        return{
            features:[],
              
        }
    },
    mounted(){
        this.fetchData();
    },
    methods:{
        async fetchData(){
            await Mainaxios.get("api/featuredNews").then(({data}) => (this.features = data));
        },
        
        
    },
    created(){

    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
img{
    object-fit: cover;
}
</style>