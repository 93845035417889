<template>
  <div>
    <!-- News With Sidebar Start -->
    <div class="container-fluid">
        <div class="container">
            <div class="row">
                <div class="col-lg-8">
                    <div class="row">
                        <div class="col-12">
                            <div class="section-title">
                                <h4 class="m-0 text-uppercase font-weight-bold">Latest News</h4>
                                <a class="text-secondary font-weight-medium text-decoration-none" href="">View All</a>
                            </div>
                        </div>
                        <div class="col-lg-6" v-for="obj in news.slice(0,2)" :key="obj.key">
                            <div class="position-relative mb-3">
                                <span v-for="(image,index) in obj.images.slice(0,1)" :key="index">
                                    <img class="img-fluid w-100" :src="$urlpath+image" style="object-fit: cover;">
                                </span>
                                <div class="bg-white border border-top-0 p-4">
                                    <div class="mb-2">
                                        <router-link :to="'/category/'+obj.categ_id" class="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2" >
                                            {{obj.categ_name}}
                                        </router-link>
                                        <a class="text-body" href=""><small>{{obj.postdate|myDate}}</small></a>
                                    </div>
                                    <router-link class="h4 d-block mb-3 text-secondary text-uppercase font-weight-bold" :to="'/single-post/'+obj.id">{{obj.title|truncate(30)}}</router-link>
                                    
                                    <p class="m-0" v-snip:js="3"  v-html="obj.content"></p>
                                </div>
                                <div class="d-flex justify-content-between bg-white border border-top-0 p-4">
                                    <div class="d-flex align-items-center">
                                        <img class="rounded-circle mr-2" :src="$profilepath+obj.profile" width="25" height="25" alt="Profile">
                                        <span>{{obj.author}}</span>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <small v-show="obj.view" class="ml-3"><i class="far fa-eye mr-2"></i>{{obj.view}}</small>
                                        <!-- <small class="ml-3"><i class="far fa-comment mr-2"></i>123</small> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <bottom-ads/>

                        <div class="col-lg-6" v-for="obj in news.slice(2,4)" :key="obj.key">
                            <div class="position-relative mb-3">
                                <span v-for="(image,index) in obj.images.slice(0,1)" :key="index">
                                    <img class="img-fluid w-100" :src="$urlpath+image" style="object-fit: cover;">
                                </span>
                                <div class="bg-white border border-top-0 p-4">
                                    <div class="mb-2">
                                        <router-link :to="'/category/'+obj.categ_id" class="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2" >
                                            {{obj.categ_name}}
                                        </router-link>
                                        <a class="text-body" href=""><small>{{obj.postdate|myDate}}</small></a>
                                    </div>
                                    <router-link class="h4 d-block mb-3 text-secondary text-uppercase font-weight-bold" :to="'/single-post/'+obj.id">{{obj.title|truncate(30)}}</router-link>
                                    
                                    <p class="m-0" v-snip:js="3"  v-html="obj.content"></p>
                                </div>
                                <div class="d-flex justify-content-between bg-white border border-top-0 p-4">
                                    <div class="d-flex align-items-center">
                                        <img class="rounded-circle mr-2" :src="$profilepath+obj.profile" width="25" height="25" alt="Profile">
                                        <span>{{obj.author}}</span>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <small v-show="obj.view" class="ml-3"><i class="far fa-eye mr-2"></i>{{obj.view}}</small>
                                        <!-- <small class="ml-3"><i class="far fa-comment mr-2"></i>123</small> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-lg-6">
                            <div class="d-flex align-items-center bg-white mb-3" v-for="obj in news.slice(4,6)" :key="obj.key" style="height: 110px;">
                                <span v-for="(image,index) in obj.images.slice(0,1)" :key="index">
                                    <img class="img-thumb" :src="$urlpath+image">
                                </span>
                                <div class="w-100 h-100 px-3 d-flex flex-column justify-content-center border border-left-0">
                                    <div class="mb-2">
                                        <router-link :to="'/category/'+obj.categ_id" class="badge badge-primary text-uppercase font-weight-semi-bold p-1 mr-2" >
                                            {{obj.categ_name}}
                                        </router-link>
                                        
                                        <a class="text-body" href=""><small>{{obj.postdate|myDate}}</small></a>
                                    </div>
                                    <router-link class="h6 m-0 text-secondary text-uppercase font-weight-bold" :to="'/single-post/'+obj.id">{{obj.title|truncate(30)}}</router-link>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="d-flex align-items-center bg-white mb-3" v-for="obj in news.slice(6,8)" :key="obj.key" style="height: 110px;">
                                <span v-for="(image,index) in obj.images.slice(0,1)" :key="index">
                                    <img class="img-thumb" :src="$urlpath+image">
                                </span>
                                <div class="w-100 h-100 px-3 d-flex flex-column justify-content-center border border-left-0">
                                    <div class="mb-2">
                                        <router-link :to="'/category/'+obj.categ_id" class="badge badge-primary text-uppercase font-weight-semi-bold p-1 mr-2" >
                                            {{obj.categ_name}}
                                        </router-link>
                                        
                                        <a class="text-body" href=""><small>{{obj.postdate|myDate}}</small></a>
                                    </div>
                                    <router-link class="h6 m-0 text-secondary text-uppercase font-weight-bold" :to="'/single-post/'+obj.id">{{obj.title|truncate(30)}}</router-link>
                                </div>
                            </div>
                        </div>
                        
                        <bottom-ads/>

                        <div class="col-lg-12">
                            <div class="row news-lg mx-0 mb-3" v-for="obj in news.slice(8,9)" :key="obj.key">
                                <div class="col-md-6 h-100 px-0" v-for="(image,index) in obj.images.slice(0,1)" :key="index">
                                    <img class="w-100 h-100" :src="$urlpath+image" style="object-fit: cover;">
                                </div>
                                <div class="col-md-6 d-flex flex-column border bg-white h-100 px-0">
                                    <div class="mt-auto p-4">
                                        <div class="mb-2">
                                            <router-link :to="'/category/'+obj.categ_id" class="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2" >
                                                {{obj.categ_name}}
                                            </router-link>
                                            <a class="text-body" href=""><small>{{obj.postdate|myDate}}</small></a>
                                        </div>
                                        <router-link class="h4 d-block mb-3 text-secondary text-uppercase font-weight-bold" :to="'/single-post/'+obj.id">{{obj.title|truncate(30)}}</router-link>
                                        <p class="m-0" v-snip:js="3"  v-html="obj.content"></p>
                                    </div>
                                    <div class="d-flex justify-content-between bg-white border-top mt-auto p-4">
                                        <div class="d-flex align-items-center">
                                            <img class="rounded-circle mr-2" :src="$profilepath+obj.profile" width="25" height="25" alt="Profile">
                                            <span>{{obj.author}}</span>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <small v-show="obj.view" class="ml-3"><i class="far fa-eye mr-2"></i>{{obj.view}}</small>
                                            <!-- <small class="ml-3"><i class="far fa-comment mr-2"></i>123</small> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6" >
                            <div class="d-flex align-items-center bg-white mb-3" v-for="obj in news.slice(9,11)" :key="obj.key" style="height: 110px;">
                                <span v-for="(image,index) in obj.images.slice(0,1)" :key="index">
                                    <img class="img-thumb" :src="$urlpath+image">
                                </span>
                                <div class="w-100 h-100 px-3 d-flex flex-column justify-content-center border border-left-0">
                                    <div class="mb-2">
                                        <router-link :to="'/category/'+obj.categ_id" class="badge badge-primary text-uppercase font-weight-semi-bold p-1 mr-2" >
                                            {{obj.categ_name}}
                                        </router-link>
                                        
                                        <a class="text-body" href=""><small>{{obj.postdate|myDate}}</small></a>
                                    </div>
                                    <router-link class="h6 m-0 text-secondary text-uppercase font-weight-bold" :to="'/single-post/'+obj.id">{{obj.title|truncate(30)}}</router-link>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6" >
                            <div class="d-flex align-items-center bg-white mb-3" v-for="obj in news.slice(11,13)" :key="obj.key" style="height: 110px;">
                                <span v-for="(image,index) in obj.images.slice(0,1)" :key="index">
                                    <img class="img-thumb" :src="$urlpath+image">
                                </span>
                                <div class="w-100 h-100 px-3 d-flex flex-column justify-content-center border border-left-0">
                                    <div class="mb-2">
                                        <router-link :to="'/category/'+obj.categ_id" class="badge badge-primary text-uppercase font-weight-semi-bold p-1 mr-2" >
                                            {{obj.categ_name}}
                                        </router-link>
                                        
                                        <a class="text-body" href=""><small>{{obj.postdate|myDate}}</small></a>
                                    </div>
                                    <router-link class="h6 m-0 text-secondary text-uppercase font-weight-bold" :to="'/single-post/'+obj.id">{{obj.title|truncate(30)}}</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <side-component/>
            </div>
        </div>
    </div>
    <!-- News With Sidebar End -->
   

    
  </div>
</template>

<script>
import Mainaxios from '../Api'
import BottomAds from './BottomAds.vue';
import SideComponent from './SideComponent.vue';
export default {
  components: { SideComponent, BottomAds },
  name: "LatestNews",
  props: {
    msg: String,
  },
  data(){
        return{
            news:[],
            paused:false,
            
        }
    },
    mounted(){
        this.fetchData();
    },
    methods:{
        async fetchData(){
            await Mainaxios.get("api/latestNews").then(({data}) => (this.news = data));
        },
    },
    created(){

    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.img-fluid {
    height: 225px !important;
    object-fit: cover !important;
}
.img-thumb {
    width: 110px !important;
    height: 110px !important;
    object-fit: cover !important;
}
</style>