<template>

<div class="col-lg-4">
    <!-- Ads Start -->
    <div class="mb-3">
        <div class="section-title mb-0">
            <h4 class="m-0 text-uppercase font-weight-bold">Most Read</h4>
        </div>
        <div class="bg-white p-3">
            <carousel :per-page="1" :autoplay="true" :loop="true" :paginationEnabled="false">
                <slide v-for="(obj,index) in sidenews.slice(0,10)" :key="index">
                    <div class="position-relative mb-3">
                            <span v-for="(image,index) in obj.images" :key="index">
                                <img class="img-fluid w-100" v-if="index==0"  :src="$urlpath+image" style="object-fit: cover;">
                            </span>
                            <div class="bg-white p-4">
                                <div class="mb-2">
                                    <a class="text-body" href=""><small>{{obj.postdate|myDate}}</small></a>
                                </div>
                                <router-link class="h6 d-block mb-3 text-secondary text-uppercase font-weight-bold" :to="'/single-post/'+obj.id">{{obj.title|truncate(30)}}</router-link>
                            </div>
                            
                        </div>
                </slide>
            </carousel>
        </div>
    </div>
    <!-- Ads End -->
    
    <!-- Social Follow Start -->
    <div class="mb-3">
        <div class="section-title mb-0">
            <h4 class="m-0 text-uppercase font-weight-bold">ADVERTISEMENT</h4>
        </div>
        <div class="bg-white border border-top-0 p-3">
            <carousel :per-page="1" :autoplay="true" :loop="true" :paginationEnabled="false">
                <slide v-for="(obj,index) in sideAds" :key="index">
                    <div class="position-relative mb-3">
                        <img class="img-fluid w-100" :src="$adspath+obj.images" style="object-fit: cover;">
                    </div>
                </slide>
                <slide>
                    <div class="position-relative mb-3">
                        <img class="img-ads" :src="$adspath+'add.jpg'" style="object-fit: cover;">
                    </div>
                </slide>
                <slide>
                    <div class="position-relative mb-3">
                        <img class="img-ads" :src="$adspath+'side_add.jpg'" style="object-fit: cover;">
                    </div>
                </slide>
            </carousel>
        </div>
    </div>
    <!-- Social Follow End -->

    

    <!-- Popular News Start -->
    <div class="mb-3">
        <div class="section-title mb-0">
            <h4 class="m-0 text-uppercase font-weight-bold">Tranding News</h4>
        </div>
        <div class="bg-white border border-top-0 p-3">
            <div class="d-flex align-items-center bg-white mb-3" v-for="(obj,index) in sidenews.slice(10,14)" :key="index" style="height: 110px;">
                <span v-for="(image,index) in obj.images" :key="index">
                    <img v-if="index==0" class="img-thumb" :src="$urlpath+image">
                </span>
                <div class="w-100 h-100 px-3 d-flex flex-column justify-content-center border border-left-0">
                    <div class="mb-2">
                        <router-link :to="'/category/'+obj.categ_id" class="badge badge-primary text-uppercase font-weight-semi-bold p-1 mr-2" >
                            {{obj.categ_name}}
                        </router-link>
                        
                        <a class="text-body" href=""><small>{{obj.postdate|myDate}}</small></a>
                    </div>
                    <router-link class="h6 m-0 text-secondary text-uppercase font-weight-bold" :to="'/single-post/'+obj.id">{{obj.title|truncate(30)}}</router-link>
                </div>
            </div>
           
        </div>
    </div>
    <!-- Popular News End -->

    <!-- Newsletter Start -->
    <div class="mb-3">
        <div class="section-title mb-0">
            <h4 class="m-0 text-uppercase font-weight-bold">Newsletter</h4>
        </div>
        <div class="bg-white text-center border border-top-0 p-3">
            <div class="input-group mb-2" style="width: 100%;">
                <input type="text" class="form-control form-control-lg" placeholder="Your Email">
                <div class="input-group-append">
                    <button class="btn btn-primary font-weight-bold px-3">Sign Up</button>
                </div>
            </div>
            <small>Subscribe to get our latest News</small>
        </div>
    </div>
    <!-- Newsletter End -->

    <!-- Tags Start -->
    <div class="mb-3">
        <div class="section-title mb-0">
            <h4 class="m-0 text-uppercase font-weight-bold">Tags</h4>
        </div>
        <div class="bg-white border border-top-0 p-3">
            <div class="d-flex flex-wrap m-n1">
                <span v-for="obj in categories" :key="obj.id">
                    <router-link :to="'/category/'+obj.id" class="btn btn-sm btn-outline-secondary m-1" >{{obj.categ_name}}</router-link>
                </span>
            </div>
        </div>
    </div>
    <!-- Tags End -->
</div>
  
   
    
 
</template>

<script>
import Mainaxios from '../Api'
// import SideAds from './SideAds.vue';
export default {
  name: "SideComponent",
  components:{
    // SideAds
  },
  props: {
    msg: String,
  },
  data(){
        return{
            sidenews:[],
            sideAds:[],
            categories:[],
        }
    },
    mounted(){
        this.fetchData();
    },
    methods:{
        async fetchData(){
            await Mainaxios.get("api/sidenews").then(({data}) => (this.sidenews = data));
            await Mainaxios.get("api/sideAds").then(({data}) => (this.sideAds = data));
            await Mainaxios.get("api/fetch_categ").then(({data}) => (this.categories = data));
        },
       
        
    },
    created(){

    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.img-fluid {
    height: 225px !important;
    object-fit: cover !important;
}
.img-thumb {
    width: 110px !important;
    height: 110px !important;
    object-fit: cover !important;
    border: 1px solid #E4E7ED !important;
    padding: 2px;
}
.img-ads{
    width: 100% !important;
    max-height: 360px !important;
}
img{
    object-fit: cover;
}
</style>