<template>
    <div class="col-lg-12 mb-3">
        <carousel :per-page="1" :autoplay="true" :loop="true" :paginationEnabled="false" :autoplayHoverPause="true"  
        :speed="1000" :autoplayTimeout="4000">
            <slide >
                <a href="http://www.pressmediaenglishdaily.com" target="blank"><img class="img-fluid w-100" src="../assets/images/add1.jpg" alt=""></a>
            </slide>
            <slide >
                <a href="https://yoututor.in" target="blank"><img class="img-fluid w-100" src="../assets/images/yoututor_2.jpg" alt=""></a>
            </slide>
        </carousel>
    </div>
</template>

<script>
import Mainaxios from '../Api'

export default {
  name: "BottomAds",
  props: {
   
  },
  data(){
        return{
            ads:[],
            
        }
    },
    mounted(){
        this.fetchData();
    },
    methods:{
        async fetchData(){
			await Mainaxios.get("api/advertisments/middle").then(({data}) => (this.ads = data));
            
        },
       
        
    },
    created(){

    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>

</style>