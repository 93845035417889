<template>
  <div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-7 px-0">
              <carousel class="position-relative" :autoplay="true" :loop="true" :perPage="1" :paginationEnabled="false">
                <slide v-for="(banner,index) in banners" :key="index">
                  <div class="position-relative overflow-hidden " style="height: 500px;">
                      <div v-for="(image,index) in banner.images" :key="index" >
                        <img class="banner-img" v-if="index <= 0" :src="$urlpath+image">
                      </div>
                        <div class="overlay">
                            <div class="mb-2">
                              <router-link :to="'/category/'+banner.categ_id" class="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2" >
                                {{banner.categ_name}}
                              </router-link>
                                <a class="text-white" href=""><fa :icon="['fas', 'clock']" /> {{banner.postdate|calendar}}</a>
                            </div>
                            <router-link class="h2 m-0 text-white text-uppercase font-weight-bold" :to="'/single-post/'+banner.id">{{banner.title|truncate(40)}}</router-link>
                        </div>
                    </div>

                </slide>
                
              </carousel>

            </div>
            <div class="col-lg-5 px-0">
                <div class="row mx-0">
                    <div v-for="(banner,index) in banners.slice(16,20)" :key="index" class="col-md-6 px-0">
                        <div class="position-relative overflow-hidden" style="height: 250px;">
                            <div v-for="(image,index) in banner.images" :key="index" >
                              <img class="stillBanner-img" v-if="index <= 0" :src="$urlpath+image">
                            </div>
                            <div class="overlay">
                                <div class="mb-2">
                                  <router-link :to="'/category/'+banner.categ_id" class="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2" >
                                    {{banner.categ_name}}
                                  </router-link>
                                    <a class="text-white" href=""><fa :icon="['fas', 'clock']" /> {{banner.postdate|calendar}}</a>
                                </div>
                                <router-link class="h6 m-0 text-white text-uppercase font-weight-semi-bold" :to="'/single-post/'+banner.id">{{banner.title|truncate(40)}}</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Main News Slider End -->

    <marquee-text :duration="200" :repeat="10" @mouseover="stopMarquee" :paused='paused' @mouseleave="startMarquee">
    <div class="latest-news-marquee-area">
        <div class="simple-marquee-container">
            <div class="marquee">
                <ul class="marquee-content-items">
                    <li v-for="marquee in marquees" :key="marquee.id">
                        <router-link :to="'/single-post/'+marquee.id"><span class="latest-news-time">{{marquee.postdate|Time}}</span>
                {{marquee.title|truncate(100)}}</router-link>
                    </li>
                    
                </ul>
            </div>
        </div>
    </div>
    </marquee-text>

   
    <!-- OLD DATA -->

    
    
  </div>
</template>

<script>
import Mainaxios from '../Api'
export default {
  name: "Banner",
  props: {
    msg: String,
  },
  data(){
        return{
            banners:[],
            marquees:[],
            paused:false,
        }
    },
    created(){
        this.fetchData();
    },
    methods:{
        async fetchData(){
            await Mainaxios.get("api/banner").then(({data}) => (this.banners = data));
            await Mainaxios.get("api/marquee").then(({data}) => (this.marquees = data));
        },
        stopMarquee(){
            this.paused = true;
        },
        startMarquee(){
            this.paused = false;
        }
        
    },
    
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.simple-marquee-container {
    width: 100%;
    display: inline-block;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    z-index: 1;
    height: 65px;
}

.marquee-content-items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0;
    position: relative;
    z-index: 1;
    height: 65px;
}

.marquee-content-items li {
    background-color: #101010;
    padding: 0 20px;
    height: 65px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.marquee-content-items li:nth-child(even) {
    background-color: #1c1c1c;
}

.marquee-content-items li a {
    font-size: 18px;
    color: #fff;
    display: inline-block;
}

.latest-news-time {
    background-color: #ff0303;
    padding: 2px 6px;
    font-size: 14px;
    font-weight: 300;
    margin-right: 20px;
}
.banner-img{
    width: 100% !important;
    height: 500px !important;
    object-fit: cover;
}
.stillBanner-img{
    width: 100% !important;
    height: 250px !important;
    object-fit: cover;
}
</style>