<template>
  <div>
    <div class="container-fluid bg-dark pt-5 px-sm-3 px-md-5 mt-5">
        <div class="row py-4">
            <div class="col-lg-3 col-md-6 mb-5">
                <h6 class="mt-4 mb-3 text-white text-uppercase font-weight-bold">Follow Us</h6>
                <div class="d-flex justify-content-start">
                    <a v-show="this.profile.facebook" :href="this.profile.facebook"  target="_blank" class="btn btn-lg btn-secondary btn-lg-square mr-2"><fa :icon="['fab', 'facebook-f']" /></a>
                    <a v-show="this.profile.twitter" :href="this.profile.twitter"  target="_blank" class="btn btn-lg btn-secondary btn-lg-square mr-2"><fa :icon="['fab', 'twitter']" /></a>
                    <a v-show="this.profile.google" :href="this.profile.google" target="_blank"  class="btn btn-lg btn-secondary btn-lg-square mr-2"><fa :icon="['fab', 'google']" /></a>
                    <a v-show="this.profile.instagram" :href="this.profile.instagram"  target="_blank" class="btn btn-lg btn-secondary btn-lg-square mr-2"><fa :icon="['fab', 'instagram']" /></a>
                    <a v-show="this.profile.youtube" :href="this.profile.youtube"  target="_blank" class="btn btn-lg btn-secondary btn-lg-square mr-2"><fa :icon="['fab', 'youtube']" /></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 mb-5">
                <h5 class="mb-4 text-white text-uppercase font-weight-bold">Popular News</h5>
                <div class="mb-3" v-for="(banner,index) in banners.slice(0,3)" :key="index">
                    <div class="mb-2">
                        <router-link :to="'/category/'+banner.categ_id" class="badge badge-primary text-uppercase font-weight-semi-bold p-1 mr-2" >
                            {{banner.categ_name}}
                        </router-link>
                        <a class="text-body" href="">{{banner.postdate|myDate}}</a>
                    </div>
                    <router-link class="small text-body text-uppercase font-weight-medium" :to="'/single-post/'+banner.id">{{banner.title|truncate(50)}}</router-link>
                </div>
                
            </div>
            <div class="col-lg-3 col-md-6 mb-5">
                <h5 class="mb-4 text-white text-uppercase font-weight-bold">Categories</h5>
                <div class="m-n1">
                    <span v-for="obj in categories" :key="obj.id">
                        <router-link :to="'/category/'+obj.id" class="btn btn-sm btn-secondary m-1" >{{obj.categ_name}}</router-link>
                    </span>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 mb-5">
                <h5 class="mb-4 text-white text-uppercase font-weight-bold">Flickr Photos</h5>
                <div class="row">
                    <div v-for="obj in footer.slice(3,9)" :key="obj.id" class="col-4 mb-3">
                        <router-link :to="'/single-post/'+obj.id" v-for="(image,index) in obj.images" :key="index">
                           
                              <img class="img-thumb" v-if="index==0" :src="$urlpath+image">
                            
                        </router-link>
                    </div>

                    
                </div>
            </div>
        </div>
    </div>


    <div class="container-fluid py-4 px-sm-3 px-md-5" style="background: #111111;">
        <p class="m-0 text-center">&copy; Copyright All rights reserved by <a href="#">India Khabar {{new Date().getFullYear()}}</a></p>
    </div>
    <!-- Footer End -->


    <!-- Back to Top -->
    <div class="btn btn-primary btn-square back-to-top" :class="{active:(scrollpx < 350)?true:false}" @click="topFunction" id="back-to-top">
        <fa :icon="['fas', 'arrow-up']" />
    </div>

    
  </div>
</template>

<script>
import Mainaxios from '../Api'
export default {
  name: "Footer",
  props: {
    msg: String,
  },
  data(){
        return{
            footer:[],
            profile:[],
            categories:[],
            banners:[],
            paused:false,
            scrollpx:null,
            
        }
    },
    mounted(){
        this.fetchData();
    },
    created () {
        window.addEventListener('scroll', this.handleScroll);
        
    },
    destroyed() {
      window.removeEventListener('scroll', this.handleScroll);
    },
    methods:{
        async fetchData(){
            await Mainaxios.get("api/profile").then(({data}) => (this.profile = data));
            await Mainaxios.get("api/fetch_categ").then(({data}) => (this.categories = data));
            await Mainaxios.get("api/banner").then(({data}) => (this.banners = data));
			await Mainaxios.get("api/footer").then(({data}) => (this.footer = data));
        },
        handleScroll() {
            this.scrollpx = window.scrollY;
        },
        topFunction() {
            window.scrollTo({top: 0, behavior: 'smooth'});
        },
       
        
    },
    
};
</script>

<style scoped>
.active{
    display: inline;
}
.img-thumb {
    width: 110px !important;
    height: 110px !important;
    object-fit: cover !important;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
