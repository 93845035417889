import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import jQuery from 'jquery';
import 'popper.js';
import 'bootstrap';

import './assets/app.scss';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(fas)
library.add(far)
library.add(fab)

Vue.component('fa', FontAwesomeIcon)

import shareIt from 'vue-share-it';
Vue.use(shareIt);

window.$ = window.jQuery = jQuery;

import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

import VueSocialSharing from 'vue-social-sharing'
Vue.use(VueSocialSharing);



import MarqueeText from 'vue-marquee-text-component'

Vue.component('marquee-text', MarqueeText)

Vue.component('pagination', require('laravel-vue-pagination'));

 
import VueSnip from 'vue-snip'

//Alerts
import Swal from 'sweetalert2'
window.Swal = Swal;


const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  //timerProgressBar: true,
  onOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
});

window.Toast = Toast;
window._ = require('lodash');


Vue.use(VueSnip)

Vue.component('Navbar', require('./components/NavBar.vue').default)
Vue.component('Banner', require('./components/Banner.vue').default)
Vue.component('Trending-News', require('./components/TrendingNews.vue').default)
Vue.component('Side-Component', require("./components/SideComponent.vue").default)
Vue.component('Categ-News', require("./components/CategComponent.vue").default)
Vue.component('CategNews', require("./components/CategNews.vue").default)
Vue.component('Video-Component', require("./components/VideoComponent.vue").default)
Vue.component('Footer-Component', require("./components/FooterComponent.vue").default)
Vue.component('Popular-News', require("./components/PopularComponent.vue").default)
Vue.component('Bottom-Side', require("./components/BottomSideComponent.vue").default)
Vue.component('Related-News', require("./components/RelatedNews.vue").default)
Vue.component('Youtube', require("./components/Videos/Videos.vue").default)


import moment from 'moment'; //dateModifier
Vue.filter('myDate', function(value){
    return moment(value).format('MMMM Do YYYY');
});

Vue.filter('formatDate', function(value){
  return moment(value).format('DD-MM-YYYY');
});

Vue.filter('dateTime', function(value){
  return moment(value).format('DD-MM-YYYY, h:mm:ss a');
});

Vue.filter('Time', function(value){
  return moment(value).format('h:mm a');
});

Vue.filter('TimeAgo', function(value){
  return moment(value).fromNow();
});

Vue.filter('calendar', function(value){
  return moment(value).calendar();
});

Vue.filter('upCase', function(text){
    return text.charAt(0).toUpperCase() + text.slice(1)
});


Vue.filter('money', function(value, sign = '₹ '){
    if(!value) return 0;
    return sign + (value);
});


Vue.filter('truncate', function (text, stop, clamp) {
    return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '')
})

Vue.prototype.$urlpath = process.env.VUE_APP_IMG_URL;
Vue.prototype.$profilepath = process.env.VUE_APP_PROFILE_URL;
Vue.prototype.$adspath = process.env.VUE_APP_ADD_URL;
Vue.prototype.$url = process.env.VUE_APP_BASE_URL;
Vue.prototype.$postUrl = process.env.VUE_APP_POST_URL;



// Vue.prototype.$url = "http://127.0.0.1:8000/";
// Vue.prototype.$urlpath = "http://127.0.0.1:8000/images/posts/";
// Vue.prototype.$profilepath = "http://127.0.0.1:8000/images/profile/";
// Vue.prototype.$adspath = "http://127.0.0.1:8000/images/ads/";

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
