<template>
  <div id="app">
    <Navbar/>
    <router-view />
    <Footer-Component />
  </div>
</template>

<script>
export default {
  name: 'App',
  data(){
        return{
    
    }
  },
  created () {
    document.title = "Indian Khabar | News Portal"
    // document.head.innerHTML += `<meta property="og:type" content="website" />`
    //             document.head.innerHTML += `<meta property="og:image:type" content="image/jpeg">`
    //             document.head.innerHTML += `<meta property="og:image:width" content="400">`
    //             document.head.innerHTML += `<meta property="og:image:height" content="400">`
    //             document.head.innerHTML += `<meta property="og:image" content="https://admin.indiakhabar.co.in/images/posts/av.jpg">`
    // document.meta = [
    //         {   name:"msapplication-TileImage",  content:"https://admin.indiakhabar.co.in/images/posts/" },
    //         {   property: 'og:site_name', content: 'India Khabar :: NewsPortal'},
    //         {   property: 'og:title', content: 'India Khabar :: '},
    //         {   property: 'og:description', content: 'this.post.title'},
    //         {   property: 'og:image', content:"https://admin.indiakhabar.co.in/images/posts/"},
    //         {   property: 'og:type', content: 'website'},
    //         {   property: "og:image:type", content: 'image/jpeg'},
    //         {   property: "og:image:width", content:"400"},
    //         {   property:"og:image:height", content:"400"},
    //         {   property: 'og:url', content: 'https://indiakhabar.co.in'},
            
    //     ]
    
  },
  methods:{
    
  }
};
</script>

<style scoped>

</style>

