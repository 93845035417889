<template>
  <div>
      <header id="header">
		<!-- Topbar Start -->
		<div class="container-fluid d-none d-lg-block">
        <div class="row align-items-center bg-dark px-lg-5">
            <div class="col-lg-9">
                <nav class="navbar navbar-expand-sm bg-dark p-0">
                    <ul class="navbar-nav ml-n2">
                        <li class="nav-item border-right border-secondary">
                            <a class="nav-link text-body small" href="#">{{ sec }}</a>
                        </li>
						<li class="nav-item border-right border-secondary"><router-link to="/about" class="nav-link text-body small">About </router-link></li>
						<li class="nav-item border-right border-secondary"><router-link to="/contact" class="nav-link text-body small">Contact</router-link></li>
						<!-- <li><router-link to="/advertisment">Advertisement</router-link></li> -->
						<li class="nav-item border-right border-secondary"><router-link to="/privacy" class="nav-link text-body small">Privacy</router-link></li>
						<li class="nav-item border-right border-secondary"><a href="https://admin.indiakhabar.co.in" target="_blank" class="nav-link text-body small"><fa :icon="['fas', 'sign-in-alt']" /> Login</a></li>
                    </ul>
                </nav>
            </div>
            <div class="col-lg-3 text-right d-none d-md-block">
                <nav class="navbar navbar-expand-sm bg-dark p-0">
                    <ul class="navbar-nav ml-auto mr-n2">
						<li class="nav-item"><a v-show="this.profile.facebook" :href="this.profile.facebook"  target="_blank" class="nav-link text-body"><fa :icon="['fab', 'facebook-f']" /></a></li>
						<li class="nav-item"><a v-show="this.profile.twitter" :href="this.profile.twitter"  target="_blank" class="nav-link text-body"><fa :icon="['fab', 'twitter']" /></a></li>
						<li class="nav-item"><a v-show="this.profile.google" :href="this.profile.google" target="_blank"  class="nav-link text-body"><fa :icon="['fab', 'google']" /></a></li>
						<li class="nav-item"><a v-show="this.profile.instagram" :href="this.profile.instagram"  target="_blank" class="nav-link text-body"><fa :icon="['fab', 'instagram']" /></a></li>
						<li class="nav-item"><a v-show="this.profile.youtube" :href="this.profile.youtube"  target="_blank" class="nav-link text-body"><fa :icon="['fab', 'youtube']" /></a></li>
                    </ul>
                </nav>
            </div>
        </div>
        <div class="row align-items-center bg-white py-3 px-lg-5">
            <div class="col-lg-4">
				<router-link to="/" class="navbar-brand p-0 d-none d-lg-block">
                    <img class="imgLogo" :src="$profilepath+this.profile.logo" alt="">
                    <!-- <h1 class="m-0 display-4 text-uppercase text-primary">India<span class="text-secondary font-weight-normal">Khabar</span></h1> -->
                </router-link>
            </div>
            <div class="col-lg-8 text-center text-lg-right">
				<top-ads />
            </div>
        </div>
    </div>
    <!-- Topbar End -->


    <!-- Navbar Start -->
    <div class="container-fluid p-0">
        <nav class="navbar navbar-expand-lg bg-dark navbar-dark py-2 py-lg-0 px-lg-5">
            <router-link to="/" class="navbar-brand d-block d-lg-none">
                <h1 class="m-0 display-4 text-uppercase text-primary">India<span class="text-white font-weight-normal">Khabar</span></h1>
            </router-link>
            <button type="button" class="navbar-toggler" :class="sideMenu?'':'collapsed'"  data-toggle="collapse" data-target="#navbarCollapse">
                <span @click="toggleMenu"  class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse justify-content-between px-0 px-lg-3" :class="sideMenu?'show':''" id="navbarCollapse">
                <div class="navbar-nav mr-auto py-0">
					<router-link to="/"  @click="toggleMenu" class="nav-item nav-link">Home</router-link>
					<span  @click="toggleMenu" v-for="(obj,index) in categories" :key="index" >
						<router-link class="nav-item nav-link" v-if="index < 5" :to="{ name: 'Category', params:{id:obj.id}}">{{obj.categ_name}}</router-link>
					</span>
					<div class="nav-item dropdown">
                        <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown">More</a>
                        <div class="dropdown-menu rounded-0 m-0">
							<span v-for="(obj,index) in categories" :key="index">
								<router-link class="dropdown-item" v-if="index >= 5" :to="{ name: 'Category', params:{id:obj.id}}">{{obj.categ_name}}</router-link>
							</span>
                            
                        </div>
                    </div>
                </div>
                <div class="input-group ml-auto d-none d-lg-flex" style="width: 100%; max-width: 300px;">
                    <input v-model="searchItem" class="form-control border-0" @keyup="search" type="text" name="search" placeholder="Search">
                    <div class="input-group-append">
                        <button class="input-group-text bg-primary text-dark border-0 px-3"><i
                                class="fa fa-search"></i></button>
                    </div>
                </div>
            </div>
        </nav>
    </div>
    <!-- Navbar End -->
		
		</header>
		<!-- /Header -->
    
  </div>
</template>

<script>
import moment from 'moment'; //dateModifier
import Mainaxios from '../Api'
import TopAds from './TopAds.vue'
import Swal from 'sweetalert2'
var _ = require('lodash');
export default {
  name: "Navbar",
  components:{
	  TopAds
  },
  props: {
    msg: String,
  },
  data(){
        return{
            categories:[],
            profile:[],
            paused:false,
			sideMenu:false,
			searchMenu:false,
			searchItem:'',
			sec:null,
        }
    },
    mounted(){
        this.fetchData();
		setInterval(()=>{
			this.sec = moment().format('dddd, MMMM DD, YYYY h:mm:ss A')
		}, 1000)
    },
	methods:{
		
		async fetchData(){
            await Mainaxios.get("api/profile").then(({data}) => (this.profile = data));
			await Mainaxios.get("api/categories").then(({data}) => (this.categories = data));
        },
		toggleMenu(){
			this.sideMenu = !this.sideMenu;
		},
		toggleSearch(){
			this.searchMenu = !this.searchMenu;	
			this.searchItem = '';
		},
		
		search:_.debounce(function () {
                 Mainaxios.get("api/search?q="+this.searchItem)
                .then(({data}) => (
					this.$router.push({name: 'searchResult', params:{id:this.searchItem, posts:data.data}}),
					this.toggleSearch()
					))
                .catch( error => {
                    let status = error.response.status;
                    this.searchItem = ''; 
                    if(status==400){
                        this.toggleSearch();
                        Swal.fire({
                            title: 'No Results!',
                            text: "Try Somthing Else! No such post in our record.",
                            icon: 'warning'
                            })
                    }else{
                        Swal.fire('error!', 'There is somthing wrong. Please Try Again!','warning');
                     
                    }
                });
               
            }, 1000),
			
		
        
    },
    created(){
		

    }
};
</script>

<style scoped>
.imgLogo{
    max-height: 150px;
    margin-left: 50px;
}
.dropdown {
  position: relative;
  
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #07090c;
  min-width: 220px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}
.dropdown-content a {
  color: #fff;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.dropdown:hover .dropdown-content {display: block;}

.dropdown-content a:hover {background-color: #2c2c2c;}

@media only screen and (max-width: 767px) {
	.dropdown-content a {
		margin-left: 15px;
	}
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
